import "./AnalysisPage.css";
import "../../styles/common.css";

import { useParams, useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import AnalysisDetail from "../../components/AnalysisDetail/AnalysisDetail";

function AnalysisPage() {
    const { analysisId } = useParams();
    const location = useLocation();
    const containerRef = useRef(null);
    
    // This effect handles scrolling to top when navigating from the table
    useEffect(() => {
        // Check if we need to scroll to top based on navigation state
        if (location.state?.scrollToTop && containerRef.current) {
            // Using both approaches for maximum browser compatibility
            window.scrollTo(0, 0);
            containerRef.current.scrollIntoView({ behavior: "auto" });
        }
    }, [location, analysisId]);

    return (
        <div className="analysis-container" ref={containerRef}>
            <div className="analysis-items">
                <AnalysisDetail analysisId={analysisId} />
            </div>
        </div>
    );
}

export default AnalysisPage;
