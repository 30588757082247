import "./AnalysisDetail.css";
import { GetColorForRating } from "../../utils/FormattingUtils";

import AnalysisDetailForm from "../AnalysisDetailForm/AnalysisDetailForm";
import PDFDownload from "../PDFDownload/PDFDownload";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import SectionLoadingOverlay from "../SectionLoadingOverlay/SectionLoadingOverlay";
import CandidateRatingChart from "../CandidateRatingChart/CandidateRatingChart";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Form, Button, Modal, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import { TextWithNewLines } from "../../utils/FormattingUtils";
import { useNavigate } from "react-router-dom";
import { FaCopy } from "react-icons/fa";

const AnalysisDetail = ({
    analysisId,
    analysis_object = null,
    closeModal = null,
    refreshData = null,
}) => {
    const [analysis, setAnalysis] = useState(null);
    const [statuses, setStatuses] = useState([]);
    const [refresh, setRefresh] = useState(Math.random());
    const [showEditModal, setShowEditModal] = useState(false);
    const [showPDFModal, setShowPDFModal] = useState(false);
    const [cookies] = useCookies(["AuthorizationToken"]);
    const [isLoading, setIsLoading] = useState(false);
    const [credentials, setCredentials] = useState([]);
    const [isCredentialsLoading, setIsCredentialsLoading] = useState(false);
    const [isEmailLoading, setIsEmailLoading] = useState(false);
    const navigate = useNavigate();
    const [emailTypes, setEmailTypes] = useState([]);
    const [writingTones, setWritingTones] = useState([]);
    const [selectedEmailTypeId, setSelectedEmailTypeId] = useState("");
    const [selectedToneId, setSelectedToneId] = useState("");
    const [customInstructions, setCustomInstructions] = useState("");
    const [generatedEmailSubject, setGeneratedEmailSubject] = useState("");
    const [generatedEmailBody, setGeneratedEmailBody] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const fetchStatuses = async () => {
            try {
                const response = await axios.get(
                    "https://api.foveaai.com/evaluate/web/analyses/statuses",
                    {
                        headers: { Authorization: cookies.AuthorizationToken },
                    }
                );
                const statusesArray = Array.isArray(response.data)
                    ? response.data
                    : response.data.statuses;
                setStatuses(
                    statusesArray.sort(
                        (a, b) =>
                            a.status_order_priority - b.status_order_priority
                    )
                );
            } catch (error) {
                console.error("Error fetching statuses:", error);
            }
        };

        fetchStatuses();
    }, [cookies.AuthorizationToken]);

    useEffect(() => {
        // Declare an async function to fetch the analysis details inside the useEffect hook
        const fetchAnalysisDetails = async () => {
            setIsLoading(true);
            try {
                const analysis_response = await axios.get(
                    `https://api.foveaai.com/evaluate/web/analyses/${analysisId}`,
                    {
                        headers: { Authorization: cookies.AuthorizationToken },
                    }
                );

                const analysis_data =
                    typeof analysis_response.data === "string"
                        ? JSON.parse(analysis_response.data)
                        : analysis_response.data;

                setAnalysis(analysis_data);

                // Check if the analysis has not been viewed
                if (!analysis_data.viewed) {
                    // Update the "viewed" property to "True" in the background
                    axios
                        .put(
                            `https://api.foveaai.com/evaluate/web/analyses/${analysisId}`,
                            { ...analysis_data, viewed: true },
                            {
                                headers: {
                                    Authorization: cookies.AuthorizationToken,
                                },
                            }
                        )
                        .catch((error) => {
                            console.error(
                                "Error updating viewed status:",
                                error
                            );
                        });
                }

                console.log("Analysis data:", analysis_data);
            } catch (error) {
                console.error("Error fetching analysis details:", error);
            } finally {
                setIsLoading(false);
            }
        };

        // Fetch the analysis details if the analysis object is not provided
        if (analysis_object == null) {
            fetchAnalysisDetails();
        }

        // Otherwise, use the analysis object
        else {
            setAnalysis(analysis_object);
        }

        console.log("Analysis object:", analysis_object);
    }, [analysisId, analysis_object, cookies.AuthorizationToken, refresh]);

    useEffect(() => {
        const fetchEmailTypes = async () => {
            try {
                const response = await axios.get(
                    "https://api.foveaai.com/evaluate/web/generative/emails/email_types",
                    {
                        headers: { Authorization: cookies.AuthorizationToken },
                    }
                );
                setEmailTypes(response.data);
            } catch (error) {
                console.error("Error fetching email types:", error);
            }
        };

        fetchEmailTypes();
    }, [cookies.AuthorizationToken]);

    useEffect(() => {
        const fetchWritingTones = async () => {
            try {
                const response = await axios.get(
                    "https://api.foveaai.com/evaluate/web/generative/writing_tones",
                    {
                        headers: { Authorization: cookies.AuthorizationToken },
                    }
                );
                setWritingTones(response.data);

                // Set default tone if any
                const defaultTone = response.data.find((tone) => tone.default);
                if (defaultTone) {
                    setSelectedToneId(defaultTone.id);
                }
            } catch (error) {
                console.error("Error fetching writing tones:", error);
            }
        };

        fetchWritingTones();
    }, [cookies.AuthorizationToken]);

    useEffect(() => {
        const fetchGeneratedEmail = async () => {
            if (selectedEmailTypeId && selectedToneId) {
                try {
                    const response = await axios.get(
                        `https://api.foveaai.com/evaluate/web/generative/emails/analyses/${analysisId}/generate/${selectedEmailTypeId}`,
                        {
                            headers: {
                                Authorization: cookies.AuthorizationToken,
                            },
                            params: { tone_id: selectedToneId },
                        }
                    );

                    if (response.data) {
                        // Set fields from the GET response
                        setCustomInstructions(
                            response.data.custom_prompt || ""
                        );
                        setGeneratedEmailSubject(
                            response.data.generative_email_subject || ""
                        );
                        setGeneratedEmailBody(
                            response.data.generative_email_body || ""
                        );
                    } else {
                        // Clear only the subject and body fields
                        setGeneratedEmailSubject("");
                        setGeneratedEmailBody("");
                    }
                } catch (error) {
                    console.error("Error fetching generated email:", error);
                    // Clear only the subject and body fields on error
                    setGeneratedEmailSubject("");
                    setGeneratedEmailBody("");
                }
            } else {
                // Clear only the subject and body fields when dropdowns are reset
                setGeneratedEmailSubject("");
                setGeneratedEmailBody("");
            }
        };

        fetchGeneratedEmail();
    }, [
        selectedEmailTypeId,
        selectedToneId,
        analysisId,
        cookies.AuthorizationToken,
    ]);

    // Add new useEffect to fetch credentials
    useEffect(() => {
        const fetchCredentials = async () => {
            if (!analysisId) return;
            
            setIsCredentialsLoading(true);
            try {
                const response = await axios.get(
                    `https://api.foveaai.com/evaluate/web/credentials/analyses/${analysisId}`,
                    {
                        headers: { Authorization: cookies.AuthorizationToken },
                    }
                );
                
                const credentialsData = typeof response.data === "string"
                    ? JSON.parse(response.data)
                    : response.data;
                
                setCredentials(credentialsData.credentials || []);
            } catch (error) {
                console.error("Error fetching credentials:", error);
                setCredentials([]);
            } finally {
                setIsCredentialsLoading(false);
            }
        };

        fetchCredentials();
    }, [analysisId, cookies.AuthorizationToken, refresh]);

    const handleEditModalClose = () => {
        setShowEditModal(false);
    };

    const handlePDFModalClose = () => {
        setShowPDFModal(false);
    };

    const handleRefreshData = () => {
        setRefresh(Math.random());
    };

    const handleDeleteAnalysis = async () => {
        setIsLoading(true);
        try {
            // Display a confirmation dialog before deleting the job
            if (
                !window.confirm(
                    "Are you sure you want to delete this evaluation? This action cannot be undone."
                )
            ) {
                return;
            }

            const response = await axios.delete(
                `https://api.foveaai.com/evaluate/web/analyses/${analysis.analysis.id}`,
                {
                    headers: { Authorization: cookies.AuthorizationToken },
                }
            );
            console.log("Completed analysis delete:", response.data);

            // Redirect to the job page if we are not there
            navigate(`/job/${analysis.analysis.job_id}`);

            // Refresh the data if the parent component provides a refreshData function
            if (refreshData) refreshData();

            // Close the modal if the parent component provides a closeModal function
            if (closeModal) closeModal();
        } catch (error) {
            console.error("Error deleting analysis:", error);

            // Alert the user that there was an issue deleting the evaluation
            alert("Error deleting the evaluation. Please try again later.");

            // Redirect to the job page if we are not there
            navigate(`/job/${analysis.analysis.job_id}`);

            // Close the modal if the parent component provides a closeModal function
            if (closeModal) closeModal();
        } finally {
            // Remove the dim from the component
            setIsLoading(false);
        }
    };

    const handleStatusChange = async (newStatusId) => {
        if (analysis && analysis.analysis.status_id === newStatusId) {
            newStatusId = 1; // Set to "No Status" if the same button is clicked
        }

        try {
            await axios.put(
                `https://api.foveaai.com/evaluate/web/analyses/${analysisId}`,
                { status_id: newStatusId },
                {
                    headers: { Authorization: cookies.AuthorizationToken },
                }
            );
            setAnalysis((prev) => ({
                ...prev,
                analysis: { ...prev.analysis, status_id: newStatusId },
            }));
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };

    const getUnicodeCharacter = (unicodeString) => {
        // Remove 'U+' and parse the remaining hex value to an integer
        const codePoint = parseInt(unicodeString.replace("U+", ""), 16);
        return String.fromCodePoint(codePoint);
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard
            .writeText(generatedEmailBody)
            .then(() => {
                console.log("Email body copied to clipboard");
            })
            .catch((error) => {
                console.error("Failed to copy email body: ", error);
            });
    };

    const handleWriteEmail = async () => {
        if (!selectedEmailTypeId || !selectedToneId) return;

        try {
            setIsEmailLoading(true);
            const response = await axios.post(
                `https://api.foveaai.com/evaluate/web/generative/emails/analyses/${analysisId}/generate/${selectedEmailTypeId}`,
                {
                    tone_id: selectedToneId,
                    custom_prompt: customInstructions,
                },
                {
                    headers: { Authorization: cookies.AuthorizationToken },
                }
            );

            if (response.status === 200 && response.data) {
                // Use the POST response to set the fields
                setGeneratedEmailSubject(response.data.subject || "");
                setGeneratedEmailBody(response.data.body || "");
                // Set custom instructions only if provided by the response
                if (response.data.custom_prompt) {
                    setCustomInstructions(response.data.custom_prompt);
                }
            } else {
                setErrorMessage("Failed to generate email. Please try again.");
            }
        } catch (error) {
            console.error("Error generating email:", error);
            setErrorMessage("Failed to generate email. Please try again.");
        } finally {
            setIsEmailLoading(false);
        }
    };

    return (
        <div className="analysis-detail-container">
            {isLoading && <LoadingOverlay />}
            <Modal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                size="lg"
                aria-labelledby="contained-model-title-vcenter"
                centered
                dialogClassName="analysis-detail-modal-dialog-custom"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-model-title-vcenter">
                        Edit Evaluation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="analysis-detail-modal-body-custom">
                    <AnalysisDetailForm
                        analysisId={analysisId}
                        closeModal={handleEditModalClose}
                        refreshData={handleRefreshData}
                        initialTabIndex={showEditModal === 'notes' ? 2 : 0}
                    />
                </Modal.Body>
            </Modal>

            <Modal
                show={showPDFModal}
                onHide={() => setShowPDFModal(false)}
                size="lg"
                aria-labelledby="contained-model-title-vcenter"
                centered
                dialogClassName="analysis-detail-modal-dialog-custom"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-model-title-vcenter">
                        Items Included in Candidate Profile
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="analysis-detail-modal-body-custom">
                    <PDFDownload
                        analysisId={analysisId}
                        closeModal={handlePDFModalClose}
                    />
                </Modal.Body>
            </Modal>

            <div className="common-buttons-container">
                <Button
                    variant="dark"
                    className="analysis-button"
                    onClick={() => {
                        navigate(`/job/${analysis.analysis.job_id}`);
                    }}
                >
                    Back
                </Button>
                <Button
                    variant="secondary"
                    className="analysis-button"
                    onClick={() => setShowEditModal(true)}
                >
                    Edit
                </Button>
                <Button
                    variant="danger"
                    className="analysis-button"
                    onClick={handleDeleteAnalysis}
                >
                    Delete Candidate
                </Button>
                <Button
                    variant="primary"
                    className="analysis-button"
                    style={{ marginTop: 0 }}
                    onClick={() => setShowPDFModal(true)}
                >
                    Download
                </Button>
            </div>

            <div className="common-detail">
                <div className="common-detail-container-main-heading">
                    {analysis ? analysis.first_name : ""}{" "}
                    {analysis ? analysis.last_name : ""}
                </div>
                <div className="common-detail-grid">
                    <div className="common-detail-label">
                        <div className="rating-label">Rating:</div>
                        {analysis && (
                            <div className="rating-circle-detail-container">
                                <div
                                    className="rating-circle-detail"
                                    style={{
                                        backgroundColor: GetColorForRating(
                                            analysis.analysis
                                        ),
                                    }}
                                >
                                    {
                                        analysis.analysis
                                            .job_fit_rating_normalized
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="common-detail-value job-fit-rating-chart">
                        <CandidateRatingChart
                            minYears={
                                analysis
                                    ? analysis.analysis
                                          .job_fit_rating_calculation_min_years
                                    : 0
                            }
                            maxYears={
                                analysis
                                    ? analysis.analysis
                                          .job_fit_rating_calculation_max_years
                                    : 0
                            }
                            valueToGraph={
                                analysis
                                    ? analysis.analysis
                                          .job_fit_rating_calculation_total
                                    : 0
                            }
                        />
                    </div>
                    <div className="common-detail-label">Set Status:</div>
                    <div className="common-detail-value status-buttons">
                        {statuses.map(
                            (status) =>
                                status.status_id > 1 && (
                                    <OverlayTrigger
                                        key={`overlay-${status.status_id}`}
                                        placement="top"
                                        overlay={
                                            <Tooltip
                                                id={`tooltip-${status.status_id}`}
                                            >
                                                {status.status_name}
                                            </Tooltip>
                                        }
                                        delay={{ show: 500, hide: 0 }}
                                    >
                                        <Button
                                            key={status.status_id}
                                            className={`status-button ${
                                                analysis?.analysis
                                                    ?.status_id ===
                                                status.status_id
                                                    ? "active"
                                                    : ""
                                            }`}
                                            style={{
                                                borderColor:
                                                    analysis?.analysis
                                                        ?.status_id ===
                                                    status.status_id
                                                        ? status.status_color_code
                                                        : "gray",
                                                backgroundColor:
                                                    analysis?.analysis
                                                        ?.status_id ===
                                                    status.status_id
                                                        ? status.status_color_code +
                                                          "33"
                                                        : "white",
                                                color: status.status_color_code,
                                            }}
                                            onClick={() =>
                                                handleStatusChange(
                                                    status.status_id
                                                )
                                            }
                                        >
                                            {getUnicodeCharacter(
                                                status.status_unicode_icon
                                            )}
                                        </Button>
                                    </OverlayTrigger>
                                )
                        )}
                    </div>
                    <div className="common-detail-label">
                        Primary Job Title:
                    </div>
                    <div className="common-detail-value">
                        {analysis ? analysis.primary_job_title : ""}
                    </div>
                    <div className="common-detail-label">Location:</div>
                    <div className="common-detail-value">
                        {analysis && (
                            <>
                                {analysis.locality &&
                                analysis.administrative_area
                                    ? `${analysis.locality}, ${analysis.administrative_area} `
                                    : `${analysis.locality || ""}${
                                          analysis.administrative_area || ""
                                      } `}
                                {analysis.postal_code || ""}
                            </>
                        )}
                    </div>
                    <div className="common-detail-label">Commute:</div>
                    <div className="common-detail-value">
                        {analysis &&
                        analysis.analysis.driving_duration &&
                        analysis.analysis.driving_distance ? (
                            analysis.analysis.driving_duration !== 0 &&
                            analysis.analysis.driving_distance !== 0 ? (
                                <>
                                    {(
                                        analysis.analysis.driving_distance /
                                        1609.34
                                    ).toFixed(0)}{" "}
                                    miles |{" "}
                                    {Math.floor(
                                        analysis.analysis.driving_duration /
                                            3600
                                    ) > 0
                                        ? `${Math.floor(
                                              analysis.analysis
                                                  .driving_duration / 3600
                                          )} hours `
                                        : ""}
                                    {Math.round(
                                        (analysis.analysis.driving_duration %
                                            3600) /
                                            60
                                    )}{" "}
                                    minutes
                                </>
                            ) : (
                                "Not Calculated"
                            )
                        ) : (
                            "Not Calculated"
                        )}
                    </div>
                    <div className="common-detail-label">Evaluated From:</div>
                    <div className="common-detail-value">
                        {analysis && analysis.source_url ? (
                            <a
                                href={analysis.source_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {new URL(analysis.source_url).hostname}
                            </a>
                        ) : (
                            "Internal Source"
                        )}
                    </div>
                    <div className="common-detail-label">Email:</div>
                    <div className="common-detail-value">
                        <a href={`mailto:${analysis ? analysis.email : ""}`}>
                            {analysis ? analysis.email : "No email provided"}
                        </a>
                    </div>
                    <div className="common-detail-label">Phone:</div>
                    <div className="common-detail-value">
                        {analysis ? analysis.phone : ""}
                    </div>
                </div>
            </div>

            <div className="common-detail">
                <div className="common-detail-container-heading">Job Fit</div>
                <div className="common-detail-grid">
                    <div className="common-detail-label">Summary:</div>
                    <div className="common-detail-value">
                        {analysis ? (
                            <TextWithNewLines
                                text={analysis.analysis.candidate_summary}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="common-detail-label">Skill Set:</div>
                    <div className="common-detail-value">
                        {analysis ? (
                            <TextWithNewLines text={analysis.skill_set} />
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="common-detail-label">
                        Strengths & Weaknesses:
                    </div>
                    <div className="common-detail-value">
                        {analysis ? (
                            <TextWithNewLines
                                text={analysis.analysis.job_fit_narrative}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>

            {/* New Candidate Credentials section */}
            <div className="common-detail">
                {isCredentialsLoading && <SectionLoadingOverlay />}
                <div className="common-detail-container-heading">
                    Credentials
                </div>
                <div className="credentials-table-container">
                    {credentials.length > 0 ? (
                        <Table className="common-table credentials-table" bordered>
                            <thead>
                                <tr>
                                    <th>Job Credential</th>
                                    <th>Status</th>
                                    <th>Type</th>
                                    <th>Candidate Credential</th>
                                    <th>Organization</th>
                                    <th>Received</th>
                                </tr>
                            </thead>
                            <tbody>
                                {credentials.map((credential, index) => {
                                    // Determine status color and text
                                    let statusText = "";
                                    let statusColor = "";
                                    let textColor = "white";
                                    
                                    if (credential.is_satisfied) {
                                        if (credential.candidate_credential_is_inferred) {
                                            statusText = "Inferred";
                                            statusColor = "#FFC107"; // Yellow
                                            textColor = "black";
                                        } else {
                                            statusText = "Satisfied";
                                            statusColor = "#28a745"; // Green
                                        }
                                    } else {
                                        statusText = "Not Satisfied";
                                        statusColor = "#dc3545"; // Red
                                    }
                                    
                                    // Format date if exists
                                    const formattedDate = credential.candidate_credential_date_obtained
                                        ? new Date(credential.candidate_credential_date_obtained).toLocaleDateString('en-US', {
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric'
                                        })
                                        : "";
                                    
                                    return (
                                        <tr key={index}>
                                            <td>{credential.job_credential_name}</td>
                                            <td>
                                                <span 
                                                    className="credential-status-pill" 
                                                    style={{ 
                                                        backgroundColor: statusColor,
                                                        color: textColor
                                                    }}
                                                >
                                                    {statusText}
                                                </span>
                                            </td>
                                            <td>{credential.job_credential_type}</td>
                                            <td>{credential.is_satisfied ? credential.candidate_credential_name : ""}</td>
                                            <td>{credential.is_satisfied ? credential.candidate_credential_issuing_organization : ""}</td>
                                            <td>{credential.is_satisfied ? formattedDate : ""}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    ) : !isCredentialsLoading ? (
                        <div className="common-detail-body">
                            No credentials included in the job description.
                        </div>
                    ) : null}
                </div>
            </div>

            <div className="common-detail">
                <div className="common-detail-container-heading">
                    Recruiter Notes
                </div>
                <div className="common-detail-body">
                    {analysis ? (
                        <TextWithNewLines text={analysis.analysis.notes} />
                    ) : (
                        ""
                    )}
                </div>
                <div className="common-buttons-container">
                    <Button
                        variant="primary"
                        className="generative-button"
                        onClick={() => setShowEditModal('notes')}
                    >
                        Edit Notes
                    </Button>
                </div>
            </div>

            <div className="common-detail">
                {isEmailLoading && <SectionLoadingOverlay />}
                <div className="common-detail-container-heading">
                    FoveaAI Emails & Messages
                </div>
                <div className="email-section-container">
                    <div className="common-detail-grid">
                        <div className="common-detail-label">
                            <label htmlFor="emailTypeSelect">
                                Type:
                            </label>
                        </div>
                        <div className="common-detail-value">
                            <Form.Select
                                value={selectedEmailTypeId}
                                onChange={(e) =>
                                    setSelectedEmailTypeId(e.target.value)
                                }
                            >
                                <option value="">Select Type</option>
                                {emailTypes.map((type) => (
                                    <option key={type.id} value={type.id}>
                                        {type.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>

                        <div className="common-detail-label">
                            <label htmlFor="toneSelect">Writing Tone:</label>
                        </div>
                        <div className="common-detail-value">
                             <Form.Select
                                value={selectedToneId}
                                onChange={(e) => setSelectedToneId(e.target.value)}
                            >
                                <option value="">Select Tone</option>
                                {writingTones.map((tone) => (
                                    <option key={tone.id} value={tone.id}>
                                        {tone.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>

                        <div className="common-detail-label">
                            <label htmlFor="customInstructions">
                                Custom Instructions:
                            </label>
                        </div>
                        <div className="common-detail-value">
                            <textarea
                                id="customInstructions"
                                className="form-control"
                                rows="3"
                                value={customInstructions}
                                onChange={(e) =>
                                    setCustomInstructions(e.target.value)
                                }
                            ></textarea>
                        </div>

                        <div className="common-detail-label" style={{ marginTop: 40 }}>
                            <label htmlFor="generatedEmailSubject">
                                Subject:
                            </label>
                        </div>
                        <div className="common-detail-value" style={{ marginTop: 40 }}>
                            <input
                                id="generatedEmailSubject"
                                className="form-control"
                                type="text"
                                value={generatedEmailSubject}
                                readOnly
                            />
                        </div>

                        <div className="common-detail-label">
                            <label htmlFor="generatedEmailBody">
                                Body:
                            </label>
                        </div>
                        <div className="common-detail-value">
                            <textarea
                                id="generatedEmailBody"
                                className="form-control"
                                rows="10"
                                value={generatedEmailBody}
                                readOnly
                            ></textarea>
                        </div>
                    </div>
                    <div className="common-buttons-container">
                        <Button
                            variant="secondary"
                            className="generative-button"
                            onClick={handleCopyToClipboard}
                        >
                            <FaCopy /> Copy
                        </Button>
                        <Button
                            variant="primary"
                            className="generative-button"
                            onClick={handleWriteEmail}
                            disabled={!selectedEmailTypeId || !selectedToneId || isEmailLoading}
                        >
                            Create
                        </Button>
                    </div>
                </div>
            </div>

            <div className="common-detail">
                <div className="common-detail-container-heading">
                    Interview Questions
                </div>
                <div className="common-detail-body">
                    {analysis ? (
                        <TextWithNewLines
                            text={analysis.analysis.interview_questions}
                        />
                    ) : (
                        ""
                    )}
                </div>
            </div>

            <div className="common-detail">
                <div className="common-detail-container-heading">
                    Experience
                </div>
                <div className="common-detail-body">
                    {analysis &&
                    analysis.experiences &&
                    analysis.experiences.length > 0 ? (
                        analysis.experiences.map((exp, index) => (
                            <div key={index} className="experience-item">
                                <div className="experience-header">
                                    {exp.title}
                                </div>
                                <div>
                                    {exp.company ? (
                                        <TextWithNewLines text={exp.company} />
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="experience-date">
                                    {exp.from_date} to{" "}
                                    {exp.to_date || "Present"}
                                </div>
                                <div className="experience-summary">
                                    {exp.summary ? (
                                        <TextWithNewLines text={exp.summary} />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No experience provided</div>
                    )}
                </div>
            </div>
            <div className="common-detail">
                <div className="common-detail-container-heading">Education</div>
                <div className="common-detail-body">
                    {analysis &&
                    analysis.educations &&
                    analysis.educations.length > 0 ? (
                        analysis.educations.map((edu, index) => (
                            <div key={index} className="education-item">
                                <div className="education-header">
                                    {edu.school ? (
                                        <TextWithNewLines text={edu.school} />
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div>
                                    {edu.degree ? (
                                        <TextWithNewLines text={edu.degree} />
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div>
                                    {edu.major ? (
                                        <TextWithNewLines text={edu.major} />
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="education-date">
                                    {edu.from_date} to{" "}
                                    {edu.to_date || "Present"}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No education provided</div>
                    )}
                </div>
            </div>

            {/* Error Modal */}
            <Modal show={!!errorMessage} onHide={() => setErrorMessage(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setErrorMessage(null)}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AnalysisDetail;
