// ./components/ScrollToTop/ScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname, state } = useLocation();

    useEffect(() => {
        // Get the last part of the URL to identify page type
        const urlPath = pathname.split('/');
        const pageType = urlPath[1];
        
        // If we're viewing an analysis page, apply special handling
        if (pageType === 'analysis') {
            // Set a small timeout to ensure DOM is fully rendered
            // This helps with some browsers that might otherwise ignore the scroll
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'auto' // Use auto instead of smooth for more consistent behavior
                });
            }, 0);
        } else {
            // For other pages, scroll to top immediately
            window.scrollTo(0, 0);
        }
    }, [pathname, state]); // Also watch for state changes from navigation

    return null;
};

export default ScrollToTop;
